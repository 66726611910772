/**
 * This modal does triple duty:
 *  1. General inventory item edit
 *  2. Edit item for an event
 *  3. Edit item in-place while in an active event
 */
import {ChangeEvent, useEffect, useReducer, cloneElement} from 'react';
import omit from 'lodash/omit';
import {validate, reducer, flash, formatNumber, formatCurrency} from 'lib';
import {useFirebaseContext, useRefs, useSessionContext} from 'hooks';
import {product_schema} from 'schema';
import {
  setDoc, 
  updateDoc,
  doc,
  serverTimestamp,
  query,
  where,
  getDocs,
  DocumentSnapshot,
  getDoc,
} from 'firebase/firestore';
import {
  GenericObject,
  ImageUploader, 
  CdnImage,
  CancelButton,
  CheckButton,
} from 'components';
import { InventorySource } from '@kwixl/interface';
import {nanoid} from  'nanoid';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import InfoIcon from '@mui/icons-material/Info';
import InputAdornment from '@mui/material/InputAdornment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';

const defaultItem = {
  id: null,
  lot: '',
  sku: '',
  name: '',
  description: '',
  qty: 1,
  price: '',
  image: null,
  reduceQty: true,
  type: 'claim',
  meta: null,
  shipping: false,
  taxable: false,
  createdAt: null,
  published: true,
};

const typeOptions = [
  {key: 'claim', value: 'claim', text: 'Claim'},
  {key: 'sale', value: 'sale', text: 'Buy Now'},
  {key: 'bid', value: 'bid', text: 'Bid'},
];

if (process.env.REACT_APP_STAGE !== 'production') {
  typeOptions.push({key: 'reverse', value: 'reverse', text: 'Reverse'});
}

export const EditItemModal = ({
    onClose = () => {},
    item,
    source,
    disabled = false,
    trigger,
    simplified = false,
}:{
    onClose?: () => void;
    item?: DocumentSnapshot | undefined;
    source?: InventorySource | undefined;
    disabled?: boolean;
    trigger: JSX.Element;
    simplified?: boolean;
}) => {

  if (!source && item) source = item?.get('source') || {};

  const [{
    open = false, 
    product, 
    formErrors, 
    loading
  }, dispatch] = useReducer(
    reducer,
    {}
  );

  const { 
    callable,
  } = useFirebaseContext();

  const {
    organization,
  } = useSessionContext();

  const {
    inventoryRef,
    inventoryItemRef
  } = useRefs();

  useEffect(() => {
    (async () => {
      if (!organization || !open || product) return;
      const ref = doc(inventoryRef!(organization?.id || 'x_@#%'));
      const inv = await getDoc(inventoryItemRef!(organization?.id, item?.id || 'x__12%^'));
      const p: GenericObject = inv?.exists() ? { ...inv?.data(), id: inv?.id } : {...defaultItem, id: ref?.id};
      dispatch({
        product: {
          ...p,
          taxable: p.taxable ? true : false,
          shipping: p.shipping ? true : false,
          reduceQty: p.reduceQty ? true : false,
        },
      });
    })()
  }, [item, organization, open]);

  const close = () => {
    dispatch({ open: false });
    onClose();
  };

  const handleSave = async () => {

    dispatch({formErrors: {}});

    const {
      lot = '',
      sku = '',
      name,
      qty,
      price,
      reduceQty,
      type = 'claim',
      taxable = true,
      shipping = true,
      image = null,
      published = false,
      createdAt = null,
    } = product;

    const payload = {
      lot: '' + lot,
      sku: '' + sku,
      name,
      qty,
      reduceQty,
      price: '' + price, // just for validation
      type,
      taxable,
      shipping,
      image,
    };

    //if (payload.reduceQty !== true) delete payload.reduceQty;
    const errors = validate(payload, product_schema);
    if (errors) {
      dispatch({formErrors: errors});
      return;
    }

    dispatch({loading: true});

    // check that an item with the same lot does not exist
    if (!item?.id) {
      const check = await getDocs(
        query(
          inventoryRef!(organization?.id || 'x'),
          where('source.id', '==', source?.id) || 'x',
          where('lot', '==', `${payload.lot}`)
        ));
      if (!!check?.docs?.length) {
        dispatch({ 
          loading: false,
          formErrors: {
            lot: `An item with this lot already exists for this ${source?.type === 'post' ? 'post' : 'sale'}.`
          }
        });
        flash.error(`An item with lot ${payload.lot} already exists for this ${source?.type === 'post' ? 'post' : 'sale'}.`);
        return;
      }
    }

    try {
      const data: GenericObject = {
        ...product,
        published,
        orgId: organization?.id,
        deletedAt: null,
      };
      if (source) data.source = source;
      if (!createdAt) {
        data.createdAt = serverTimestamp();
      }
      const ref = product?.id 
        ? inventoryItemRef!(organization?.id || 'x', product.id) 
        : doc(inventoryRef!(organization?.id || 'x'));
      await setDoc(ref, data);
      flash.success('Item saved!');
      close();
      if (source?.type === 'post' && published) {
        const { data } = await callable!('facebook-publish', {
            action: 'comment',
            message: `Lot #${lot}: ${name} $${price} (${qty} avail) ${description ? ` - ${description}` : ''}`,
            image,
            postId: source.id,
            productId: ref.id,
        });
        if (data?.success) {
            flash.success('Product published.');
            // update inventory record
            await updateDoc(ref, { published: true });
        } else {
            flash.error(`Error publishing post: ${data?.error || 'Unknown error'} `);
        }
      }
    } catch (err: any) {
      console.log(err);
      flash.error(`Error saving item: ${err.message}`);
    } finally {
      dispatch({loading: false});
    }
  };

  const handleMeta = ({ target: {name, value} }: ChangeEvent<HTMLInputElement>) => {
    let {meta} = product;
    if (!meta) {
      meta = {};
    }
    meta[name] = value;
    dispatch({product: {...product, meta}});
  };

  const handleChange = (name: string, value: string | number | boolean) => {
    let val = value;
    let opts = {};
    switch (name) {
      case 'type':
        switch (value) {
          case 'bid':
            opts = {
              reduceQty: true,
              qty: 1,
              meta: {increment: 1},
            };
            break;
          case 'reverse':
            opts = {
              reduceQty: true,
              qty: 1,
              meta: {decrement: 1, minPrice: 1, interval: 60},
            };
            break;
        }
        break;
      case 'name':
        if ((value as string).length > 45) {
          val = (value as string).substring(0, 45);
        }
        break;
      case 'price':
        val = parseFloat((value as string) || '0');
        break;
      case 'qty':
        val = parseInt((value as string) || '1');
        break;
      default:
        break;
    }
    dispatch({
      product: {
        ...product,
        [name]: val,
        ...opts,
      },
      formErrors: omit(formErrors, name),
    });
  };

  const handleSelectChange = ({ target: { name, value }}: SelectChangeEvent<HTMLInputElement>) => 
    handleChange(name, value as string);

  const handleInputChange = ({ target: {name, value}}: ChangeEvent<HTMLInputElement>) => 
    handleChange(name, value);

  const handleMoneyChange = ({ target: {name, value}}: ChangeEvent<HTMLInputElement>) => 
    handleChange(name, formatCurrency(value));

  const handleNumberChange = ({ target: {name, value}}: ChangeEvent<HTMLInputElement>) => 
    handleChange(name, formatNumber(value));

  const handleCheck = ({ target: {name, checked}}: ChangeEvent<HTMLInputElement>) => 
    handleChange(name, checked);

  const {
    lot,
    sku,
    name,
    description,
    qty,
    price,
    image,
    reduceQty = true,
    type = 'claim',
    meta,
    taxable = false,
    shipping = true,
    published = false,
  } = product || {};

  const MIN_INCREMENT = 1;

  const Trigger = () => cloneElement(
    trigger, { 
      disabled: disabled || open, 
      onClick: () => dispatch({ open: true }),
      sx: { cursor: 'pointer' }
    }
  );

  console.log('Product', product);

  return (
    <>
    <Trigger/>
    <Dialog
      maxWidth={simplified ? 'sm' : 'lg'}
      open={open}
      disableEscapeKeyDown={true}
      onClose={close}
    >
      <DialogTitle>
        <Grid container>
          <Grid item xs={12} md={9}>
            {product?.id ? 'Edit Item' : 'Add Item'} { source?.name ? `for ${source?.name}` : null }
          </Grid>
          <Grid item xs={12} md={2} textAlign={{ xs: 'left', md: 'right'}}>
            <FormControlLabel 
              label='Published'
              control={
                <Switch
                  name='published'
                  checked={published}
                  onChange={handleCheck}
                />
              }
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item container xs={12} md={simplified ? 12 : 6}> 
              { source?.type !== 'post' && (
                <>
                  <Grid item xs={6} mb={2}>
                    <FormControl fullWidth>
                      <InputLabel>Sale Type</InputLabel>
                      <Select
                          label="Sale Type"
                          name="type"
                          value={type}
                          onChange={handleSelectChange}
                        >
                          {typeOptions.map(option => (
                            <MenuItem value={option.value}>
                                {option.text}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                <Grid item xs={6} pl={3} pt={2}>
                  <Tooltip 
                      color="info"
                      title={
                        <>
                          <p>
                            <b>Claim Sale</b>
                            <br />
                            Buyers claim products and pay for items after the sale
                            closes.
                            <br />
                            <small>
                              Best for: Sales with many items and items with
                              multiple quantities.
                            </small>
                          </p>
                          <p>
                            <b>Buy Now</b>
                            <br />
                            Buyers purchase each item separately in real-time.
                            <br />
                            <small>
                              Best for: Sales with only a few items, items that do
                              not need to be shipped (unless shipping is included
                              in the price.)
                            </small>
                          </p>
                          <p>
                            <b>Bid</b>
                            <br />
                            Buyers bid in real-time against each other for an
                            item.
                            <br />
                            <small>
                              Best for: hard-to find items or items in high
                              demand.
                            </small>
                          </p>
                        </>
                      }
                    >
                      <InfoIcon/>
                    </Tooltip>
                  </Grid>
                </>
              )}
              <Grid item xs={12} mb={2}>
                <FormControl fullWidth>
                  <TextField 
                    label="Item name"
                    name="name" 
                    value={name} 
                    onChange={handleInputChange} 
                    error={formErrors?.name}
                    helperText={formErrors?.name || ''}
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={type === 'bid' ? 4 : type === 'reverse' ? 4 : 6}>
                  <FormControl fullWidth>
                    <TextField
                      label={(() => {
                        switch (type) {
                          case 'bid':
                            return 'Starting Bid';
                          case 'reverse':
                            return 'Starting Price';
                          default:
                            return 'Price';
                        }
                      })()}
                      name="price"
                      value={price}
                      onChange={handleMoneyChange}
                      InputProps={{
                          startAdornment: (
                              <InputAdornment position="start">
                                <AttachMoneyIcon fontSize="small"/>
                              </InputAdornment>
                          )
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </Grid>
                {type === 'reverse' && (
                  <>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <TextField
                            label="Floor Price"
                            name="minPrice"
                            type="number"
                            value={meta?.minPrice ? meta.minPrice : 1}
                            onChange={handleMeta}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                      <AttachMoneyIcon fontSize="small"/>
                                    </InputAdornment>
                                ),
                                endAdornment:(
                                  <InputAdornment position="end">
                                    <Tooltip title="The minimum amout you will accept for this item.">
                                      <InfoIcon fontSize="small" color="info"/>
                                    </Tooltip>
                                  </InputAdornment>
                                ),
                                inputProps: { min: 0 }
                            }}
                            error={formErrors?.minPrice}
                            helperText={formErrors?.minPrice || ''}
                            InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <TextField
                          label="Decrement"
                          name="decrement"
                          type="number"
                          value={!!meta && meta.decrement ? meta.decrement : 1}
                          onChange={handleMeta}
                          InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AttachMoneyIcon fontSize="small"/>
                                </InputAdornment>
                            ),
                            endAdornment:(
                              <InputAdornment position="end">
                                <Tooltip title="The amount to reduce the price each interval until the item is purchased or the minimum price is reached.">
                                  <InfoIcon fontSize="small" color="info"/>
                                </Tooltip>
                              </InputAdornment>
                            ),
                            inputProps: { min: 1 }
                          }}
                          error={formErrors?.decrement}
                          helperText={formErrors?.decrement || 'x'}
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <TextField
                          label="Interval (sec)"
                          name="interval"
                          type="number"
                          value={meta?.interval || 30}
                          onChange={handleMeta}
                          InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <TimerOutlinedIcon fontSize="small"/>
                                </InputAdornment>
                            ),
                            endAdornment:(
                              <InputAdornment position="end">
                                <Tooltip title="The price will be reduced by the decrement amount every interval specified here.">
                                  <InfoIcon fontSize="small" color="info"/>
                                </Tooltip>
                              </InputAdornment>
                            ),
                            inputProps: { min: 1 }
                          }}
                          error={formErrors?.interval}
                          helperText={formErrors?.interval || ''}
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
                {type === 'bid' && (
                  <Grid item xs={4}>
                    <TextField
                      label="Increment"
                      name="increment"
                      type="number"
                      value={
                        !!meta && meta.increment
                          ? meta.increment
                          : MIN_INCREMENT
                      }
                      onChange={handleMeta}
                      InputProps={{
                        inputProps: { min: MIN_INCREMENT },
                        startAdornment: (
                            <InputAdornment position="start">
                                <AttachMoneyIcon fontSize="small"/>
                            </InputAdornment>
                        )
                      }}
                      error={formErrors?.increment}
                      helperText={formErrors?.increment || ''}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                )}
                <Grid item xs={type === 'bid' ? 4 : type === 'reverse' ? 4 : 6}>
                  <FormControl fullWidth>
                    <TextField
                      label="Qty Available"
                      name="qty"
                      type="number"
                      InputProps={{
                          inputProps: { min: 0 }
                      }}
                      value={qty}
                      onChange={handleNumberChange}
                      disabled={
                        !reduceQty || type === 'bid' || type === 'reverse'
                      }
                      error={formErrors?.qty}
                      helperText={formErrors?.qty || ''}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField 
                      label="Lot"
                      name="lot" 
                      value={lot} 
                      onChange={handleInputChange} 
                      error={formErrors?.lot}
                      helperText={formErrors?.lot || ''}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField 
                      label="SKU (optional)"
                      name="sku" 
                      value={sku} 
                      onChange={handleInputChange} 
                      error={formErrors?.sku}
                      helperText={formErrors?.sku || ''}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              {!simplified && (
                <Grid item xs={12} mb={2} mt={2}>
                  <FormControl fullWidth>
                    <TextField
                      label="Product description"
                      multiline
                      name="description"
                      rows={3}
                      value={description}
                      onChange={handleInputChange}
                      error={formErrors?.description}
                      helperText={formErrors?.description || ''}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12}>
                <Stack direction="column" spacing={2}>
                  <FormControlLabel
                    label={
                      <>
                        Item is taxable
                        <Tooltip title="Choose whether or not this item should have sales tax applied. You can configure the sales tax in your payment settings.">
                            <InfoIcon color="info" sx={{verticalAlign:'text-bottom', ml:'.3em'}}/>
                        </Tooltip>
                      </>
                    }
                    control={
                      <Switch
                        name="taxable"
                        checked={taxable}
                        onChange={handleCheck}
                      />
                    }
                  />
                  <FormControlLabel
                    label={
                      <>
                        Requires shipping
                        <Tooltip title="Choose whether or not this item can be shipped. Invoices that do not include any shippable items can be paid immediately if allowed by your payment settings.">
                          <InfoIcon color="info" sx={{verticalAlign:'text-bottom', ml:'.3em'}}/>
                        </Tooltip>
                      </>
                    }
                    control={
                      <Switch
                        name="shipping"
                        checked={shipping}
                        onChange={handleCheck}
                      />
                    }
                  />
                  <FormControlLabel
                    label={
                      <>
                        Claims deplete inventory
                        <Tooltip title="Each claim/purchase reduces the available items by the purchase quantity.  Uncheck this for items that do not have a fixed quantity available.">
                          <InfoIcon color="info" sx={{verticalAlign:'text-bottom', ml:'.3em'}}/>
                        </Tooltip>
                      </>
                    }
                    control={
                      <Switch
                        name="reduceQty"
                        checked={reduceQty || type === 'bid' || type === 'reverse'}
                        onChange={handleCheck}
                        disabled={type === 'bid' || type === 'reverse'}
                      />
                    }
                  />
                </Stack>
              </Grid>
            </Grid>
            {!simplified && (
              <Grid item xs={12} md={6} id="right_col">
                <ImageUploader
                  header="Upload Product Image"
                  trigger={<CdnImage src={image} noCache/>}
                  dest={{
                    path: `store/${organization?.id}/products/${product?.id}`,
                    name: sku || nanoid(8),
                  }}
                  onPrepare={data => {
                    return data;
                  }}
                  onSuccess={downloadUrl => {
                    dispatch({
                      product: {
                        ...product,
                        image: downloadUrl,
                      },
                    });
                  }}
                  onError={() => {}}
                />
              </Grid>
            )}
          </Grid>
      </DialogContent>
      <DialogActions style={{textAlign: 'center'}}>
        <CancelButton disabled={loading} onClick={() => close()}/>
        <CheckButton 
          disabled={loading || (!product?.lot || !product?.price || !product?.name || (product?.reduceQty && !product.qty))}
          loading={loading}
          onClick={() => handleSave()}
        />
      </DialogActions>
    </Dialog>
    </>
  );
};
